import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import { useContext } from '~/hooks'
import {
  Head,
  Breadcrumb,
  Lazyload,
  Plants,
  Tabs,
  Gallery,
  Roof,
  Location,
  Form,
  Button,
  Link,
  Footer,
} from '~/components'
// import parse from 'html-react-parser'
import nl2br from 'react-nl2br'
import { scroller } from 'react-scroll'
import cn from 'classnames'
import * as st from '~/assets/styl/Enterprise.module.styl'
import * as st1 from '~/assets/styl/EnterpriseSlider.module.styl'
import * as st2 from '~/assets/styl/Hero.module.styl'

const Header = styled.header<{ image: string }>`
  ${({ image }) =>
    image &&
    css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0,
          rgba(0, 0, 0, 0.5) 100%
        ),
        url(${image}) center / cover;
    `}
`

const Bar = styled.li<{ value: string }>`
  min-height: 2px;
  ${({ value }) =>
    css`
      height: ${value}%;
    `}
`

interface Context {
  slug: string
  enterprise: {
    id: number
    name: string
    slug: string
    tag: string
    delivery: string
    room: string
    metreage: string
    floor: string
    unit: string
    apartment: string
    slogan: string
    description: string
    characteristicTitle: string
    characteristicEnterpriseTitle: string
    characteristicEnterpriseItems: Array<{
      name: string
      icon: string
    }>
    characteristicApartmentTitle: string
    characteristicApartmentItems: Array<{
      name: string
      icon: string
    }>
    galleryTitle: string
    galleryItems: Array<{
      id: number
      gallery: string
      title: string
      images: Array<{
        legend: string
        urls: {
          original: string
          large: string
          miniature: string
        }
      }>
      videosOrTours: Array<{
        name: string
        url: string
      }>
    }>
    decorateTitle: string
    decorateItems: Array<{
      legend: string
      urls: {
        original: string
        large: string
        miniature: string
      }
    }>
    plantTitle: string
    plantItems: Array<{
      id: number
      name: string
      images: Array<{
        legend: string
        urls: {
          original: string
          large: string
        }
      }>
    }>
    workTitle: string
    workStatusTitle: string
    workStatusItems: Array<{
      name: string
      value: string
    }>
    workPhotoTitle: string
    workPhotoItems: Array<{
      id: number
      title: string
      images: Array<{
        legend: string
        urls: {
          original: string
          large: string
          miniature: string
        }
      }>
    }>
    workVideoTitle: string
    workVideoItems: Array<{
      name: string
      url: string
    }>
    locationTitle: string
    address: string
    neighborhood: string
    city: string
    state: string
    zipCode: string
    latitude: string
    longitude: string
    formTitle: string
    formContent: string
    metaTitle: string
    metaDescription: string
    metaKeyword: string
    imageAbout: string
    imageTop: string
  }
  subjects: Array<{
    id: number
    name: string
  }>
}

const Enterprise = ({
  location,
  pageContext,
  '*': slug,
}: PageProps & { '*'?: string }) => {
  const { enterprise, subjects }: Context = useApi(
    pageContext,
    'page-enterprise/' + (slug || (pageContext as Context).slug)
  )

  const { setLoading } = useContext()
  useEffect(() => {
    setLoading(!enterprise?.name)
  }, [enterprise])

  const features = [
    enterprise?.characteristicEnterpriseItems || [],
    enterprise?.characteristicApartmentItems || [],
  ]
  const Features =
    features[0].length && features[1].length
      ? ({ children }) => (
          <Tabs
            pages={[
              enterprise.characteristicEnterpriseTitle,
              enterprise.characteristicApartmentTitle,
            ]}
            classes={{ selector: st.tabs, content: st.tabsContent }}
          >
            {children}
          </Tabs>
        )
      : ({ children }) => <>{children}</>

  const unities = (enterprise?.apartment || '').split('\n')

  const workPhotoItems = [].concat(
    ...(enterprise?.workPhotoItems || []).map(({ images }) => images)
  ) as Array<{
    legend: string
    urls: {
      original: string
      large: string
      miniature: string
    }
  }>

  return enterprise?.name ? (
    <>
      <Head
        title={`${enterprise.name} - ${process.env.GATSBY_SITE_NAME}`}
        location={location}
      />

      <Header image={enterprise.imageTop} className={st.header}>
        <div className={st.container}>
          <span className={st2.pretitle}>
            {enterprise.neighborhood} -{' '}
            {enterprise.city +
              (enterprise.state ? ' - ' + enterprise.state : '')}
          </span>
          <h1>{enterprise.name}</h1>
        </div>
      </Header>

      <Breadcrumb />

      {enterprise.slogan && (
        <section className={st.description}>
          <div className={st.container}>
            <div className={st.text}>
              <h2>{nl2br(enterprise.slogan)}</h2>
              {enterprise.description && <p>{nl2br(enterprise.description)}</p>}
              {enterprise.tag && (
                <div className={st.tags}>
                  <div>
                    <strong>{enterprise.tag}</strong>
                    {enterprise.delivery && <span>{enterprise.delivery}</span>}
                  </div>
                </div>
              )}
              <ul className={st.topics}>
                {enterprise.metreage && (
                  <li className={st.area}>
                    <strong>Área total: {enterprise.metreage}</strong>
                  </li>
                )}
                {enterprise.floor && (
                  <li className={st.floors}>
                    <strong>{enterprise.floor}</strong>
                  </li>
                )}
                {enterprise.unit && (
                  <li className={st.unities}>
                    <strong>{enterprise.unit}</strong>
                    {Boolean(unities.length) && (
                      <ul>
                        {unities.map((i, key) => (
                          <li key={key}>{i}</li>
                        ))}
                      </ul>
                    )}
                  </li>
                )}
              </ul>
            </div>
            <Lazyload src={enterprise.imageAbout} className={st.image} />
          </div>
        </section>
      )}

      {Boolean(features[0].length || features[1].length) && (
        <section className={cn(st1.core, st.pattern)}>
          <div className={st.container}>
            <h2>{enterprise.characteristicTitle}</h2>
            <Features>
              {Boolean(features[0].length) && (
                <ul>
                  {features[0].map(({ icon: image, name }, key) => (
                    <li key={key}>
                      <img src={image} width="86" height="86" />
                      <span>{name}</span>
                    </li>
                  ))}
                </ul>
              )}
              {Boolean(features[1].length) && (
                <ul>
                  {features[1].map(({ icon: image, name }, key) => (
                    <li key={key}>
                      <img src={image} width="86" height="86" />
                      <span>{name}</span>
                    </li>
                  ))}
                </ul>
              )}
            </Features>
            <div className={st.btn}>
              <Button
                onClick={() =>
                  scroller.scrollTo('SoliciteInformacoes', {
                    smooth: true,
                    duration: 500,
                    offset:
                      -0.65 * document.getElementById('menu-bar').offsetHeight,
                  })
                }
              >
                Solicite informações
              </Button>
            </div>
          </div>
        </section>
      )}

      {Boolean(enterprise.galleryItems?.length) && (
        <section className={st1.core}>
          <div className={st.container}>
            <h2>{enterprise.galleryTitle}</h2>
            <Tabs
              pages={enterprise.galleryItems
                .map(({ title, videosOrTours, images }) =>
                  videosOrTours?.length || images.length ? title : null
                )
                .filter((i) => i !== null)}
              classes={{ selector: st.tabs, content: st.tabsContent }}
            >
              {enterprise.galleryItems
                .filter(
                  ({ videosOrTours, images }) =>
                    videosOrTours?.length || images.length
                )
                .map(({ id, videosOrTours, images }) => (
                  <div key={id}>
                    <Gallery
                      type={videosOrTours?.length ? 'videos' : undefined}
                      items={
                        videosOrTours?.length
                          ? videosOrTours.map(({ url, name: legend }) => ({
                              url,
                              legend,
                            }))
                          : images.map(
                              ({
                                urls: { large: url, original: zoom },
                                legend,
                              }) => ({
                                url,
                                zoom,
                                legend,
                              })
                            )
                      }
                    />
                  </div>
                ))}
              {/* <div>
                <div className={st.iframe}>
                  <iframe src={enterprise.galleryTours[0]}></iframe>
                </div>
              </div> */}
            </Tabs>
          </div>
        </section>
      )}

      {Boolean(enterprise.decorateItems?.length) && (
        <section className={st1.core}>
          <div className={st.container}>
            <h2>{enterprise.decorateTitle}</h2>
            <Tabs
              pages={['']}
              classes={{ selector: st.tabs, content: st.tabsContent }}
            >
              <div>
                <Gallery
                  items={enterprise.decorateItems.map(
                    ({ urls: { large: url, original: zoom }, legend }) => ({
                      url,
                      zoom,
                      legend,
                    })
                  )}
                />
              </div>
            </Tabs>
          </div>
        </section>
      )}

      {enterprise.id === 1 && <Roof />}

      <Plants title={enterprise.plantTitle} items={enterprise.plantItems} />

      {Boolean(
        enterprise.workStatusItems?.length ||
          workPhotoItems.length ||
          enterprise.workVideoItems?.length
      ) && (
        <section className={st1.core}>
          <div className={st.container}>
            <h2>Acompanhe a obra</h2>
            <Tabs
              pages={[
                enterprise.workStatusItems?.length
                  ? enterprise.workStatusTitle
                  : '',
                workPhotoItems.length ? enterprise.workPhotoTitle : '',
                enterprise.workVideoItems?.length
                  ? enterprise.workVideoTitle
                  : '',
              ]}
              classes={{ selector: st.tabs, content: st.tabsContent }}
            >
              {Boolean(enterprise.workStatusItems?.length) && (
                <div className={st.chart}>
                  <ul>
                    {enterprise.workStatusItems?.map(({ name, value }, key) => (
                      <Bar value={value} key={key}>
                        <h3>{name}</h3>
                        <p>{value}%</p>
                      </Bar>
                    ))}
                  </ul>
                </div>
              )}
              {Boolean(workPhotoItems.length) && (
                <div>
                  <Gallery
                    items={workPhotoItems.map(({ urls, legend }) => ({
                      url: urls.large,
                      zoom: urls.original,
                      legend,
                    }))}
                  />
                </div>
              )}
              {Boolean(enterprise.workVideoItems?.length) && (
                <div>
                  <Gallery
                    type="videos"
                    items={enterprise.workVideoItems.map(
                      ({ url, name: legend }) => ({
                        url,
                        legend,
                      })
                    )}
                  />
                </div>
              )}
            </Tabs>
          </div>
        </section>
      )}

      <Location
        location={
          enterprise.city + (enterprise.state ? ' / ' + enterprise.state : '')
        }
        latitude={enterprise.latitude}
        longitude={enterprise.longitude}
      >
        {enterprise.address}
        {(enterprise.state || enterprise.neighborhood) && <br />}
        {enterprise.neighborhood
          ? enterprise.neighborhood + (enterprise.state ? ', ' : '')
          : ''}
        {enterprise.state && enterprise.state}
        {enterprise.zipCode && (
          <>
            <br />
            CEP:{' '}
            {enterprise.zipCode
              .toString()
              .replace(/\D/g, '')
              .replace(/(\d{5})(\d)/, '$1-$2')
              .replace(/(-\d{3})\d+?$/, '$1')}
          </>
        )}
      </Location>

      <section
        className={cn(st1.core, st.formWrapper, 'keep-padding')}
        id="SoliciteInformacoes"
      >
        <div className={st1.container}>
          <h2>Solicite mais informações</h2>
          <p>
            Entre em contato com nosso time de consultores para obter mais
            informações.
          </p>
          <Form
            path="page-enterprise"
            idPrefix="contact"
            button={<Button>Enviar mensagem</Button>}
            loadingButton={<Button>Enviando...</Button>}
            hiddenInputs={{
              enterprise: enterprise.name,
            }}
            inputs={[
              {
                name: 'name',
                label: 'Nome*',
                className: 'small',
              },
              {
                name: 'email',
                label: 'E-mail*',
                type: 'email',
                className: 'small',
              },
              {
                name: 'phone',
                label: 'WhatsApp*',
                mask: 'phone',
                className: 'small',
              },
              {
                name: 'subject',
                label: 'Interesse do contato*',
                type: 'select',
                options: subjects.map(({ name }) => ({
                  name,
                })),
                placeholder: 'Selecione',
                className: 'small',
              },
              {
                name: 'message',
                label: 'Mensagem*',
                type: 'textarea',
              },
              {
                name: 'privacy',
                label: (
                  <>
                    Declaro que li e aceito a{' '}
                    <Link href="/politica-de-privacidade/">
                      Política de Privacidade
                    </Link>
                  </>
                ),
                type: 'checkbox',
              },
            ]}
          />
        </div>
      </section>

      <Footer />
    </>
  ) : (
    <></>
  )
}

export default Enterprise
